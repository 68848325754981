import { Message } from '@/components/Message'
import { mutateFn } from '@/graphql'
import * as storage from '@/utils/storage'

// 保存步骤信息
function useMutationCommon<P> (params: P) {
  return mutateFn('saveClientFormDraft', {
    params: {
      formType: storage.get('formType'),
      meta: 'open_common',
      content: JSON.stringify(params)
    }
  }).then(res => {
    const { ok, reason } = res
    if (ok) {
      console.log('ok', ok)
    } else {
      Message({ text: reason?.desc || '请求异常', direction: 'vertical' })
    }
  })
}

export default useMutationCommon
