
import { computed, defineComponent, onMounted, provide, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import DataInput from './ components/DataInput.vue'
import useOpenInfo from '@/hooks/openInfo'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import useQueryFileTemp from '@/hooks/useFileTemp'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import { setApptitle } from '@/utils/native-app'
import { queryFn } from '@/graphql'
import * as storage from '@/utils/storage'

export default defineComponent({
  components: { DataInput },
  setup () {
    const router = useRouter()
    // 查询数据字典
    const dicResult = ref()
    const nationOptions = ref()
    const codeOptions = ref()
    const checkedContent = ref()
    const formTypeVal = computed(() => storage.get('formType'))
    provide('nationOptions', nationOptions)
    provide('codeOptions', codeOptions)
    // vuex store数据
    const {
      openType,
      openInfo,
      meta,
      title: headTitle,
      step,
      saveOpenInfoOfVuex,
      saveCommonOfVuex
    } = useOpenInfo()
    const { idKind } = openInfo.value
    // const checked = ref(
    //   idKind === 2 || idKind === 3
    //     ? [14, 15, 16, 1, 28, 3, 4, 5, 6, 7, 8]
    //     : [14, 15, 16, 1, 28, 3, 4, 5, 6, 7, 8, 24]
    // )
    const checked = ref([27, 14, 15, 16, 1, 28, 3, 4, 5, 6, 7, 8, 29])
    // 获取缓存图片信息
    const imagesList = useQueryFileTemp({
      kindSource: openType.value
    })

    // 用户信息回填
    const initData = async () => {
      // console.log(openInfo.value.checked, checked.value)
      if (openInfo.value?.checked?.includes(24)) {
        const index = openInfo.value.checked.findIndex((item: number) => item === 24)
        const index2 = openInfo.value.checked.findIndex((item: number) => item === 2)
        openInfo.value.checked.splice(index, 1)
        openInfo.value.checked.splice(index2, 1)
      }
      checked.value = openInfo.value.checked ? openInfo.value.checked : checked.value
      const res = await queryFn('findDictOpenAccount', {
        markList: ['AO_NATIONALITY', 'AREA_CODE', 'DISCLOSURE_CODE']
      })
      dicResult.value = res?.data
      nationOptions.value = dicResult.value.AO_NATIONALITY.map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          value: val.value
        })
      )
      codeOptions.value = dicResult.value.AREA_CODE.map((val: { name: string; value: string }) => ({
        text: val.value,
        value: val.value
      }))
      const checkedList = dicResult.value.DISCLOSURE_CODE.filter(
        (item: { value: string }) =>
          item.value === '27' ||
          item.value === '14' ||
          item.value === '15' ||
          item.value === '16' ||
          item.value === '1' ||
          item.value === '3' ||
          item.value === '4' ||
          item.value === '5' ||
          item.value === '6' ||
          item.value === '7' ||
          item.value === '8' ||
          item.value === '28' ||
          item.value === '29'
      )

      checkedContent.value = checkedList.map((val: { name: string; value: string }) => ({
        text: val.name,
        name: Number(val.value)
      }))
      if (formTypeVal.value !== 'openImmigrantAccount') {
        checkedContent.value = checkedContent.value.filter((item: { name: number }) => item.name !== 29)
      }
    }

    const show = ref(false)
    const closePopup = (val: boolean) => {
      show.value = val
    }
    const title = ref('')
    const content = ref('')
    const popContent = reactive([
      {
        title: '什么是主要股东？',
        content: '任何人士有权于一间公司之任何股东大会上行使或可控制行使10%或以上之股权或投票权'
      },
      {
        title: '什么是政治人物？',
        content:
          '任何国家/省/州/城市担任或曾担任重要公职的个人，包括国家元首、政府首长、资深从政者、高级政府、司法或军事官员、国有企业高级行政人员及重要政党干事'
      },
      {
        title: '联系或关系？',
        content:
          '<p>1、为政治人物的配偶、伴侣、子女或父母，或政治人物子女的配偶或伴侣;</p>' +
          '<p>2、为政治人物拥有实益控制权的法人(即公司或企业);</p>' +
          '<p>3、为与政治人物有密切业务关係的个人，包括属法人(即公司或企业)或信託的实益拥有人的个人，而政治人物亦是该法人或信托的实益拥有人;</p>' +
          '<p>4、替政治人物持有法人(即公司或企业)或信託的个人，而有关法人是为政治人物的利益而成立的。</p>'
      }
    ])
    const openPop = (i: number) => {
      title.value = popContent[i].title
      content.value = popContent[i].content
      show.value = true
    }

    const form = reactive({
      disclosureCode14: {
        disclosureIsTrue: 1,
        disclosureType: 2,
        disclosureCode: 14,
        disclosure1: '',
        disclosure2: '',
        disclosure3: '',
        disclosure5: '',
        disclosure4: '',
        disclosure7: '',
        phone: '',
        areaCode: '+86'
      },
      disclosureCode15: {
        disclosureIsTrue: 1,
        disclosureType: 2,
        disclosureCode: 15,
        disclosure1: '',
        disclosure2: '',
        disclosure3: '',
        disclosure5: '',
        disclosure4: '',
        disclosure7: '',
        phone: '',
        areaCode: '+86'
      },
      disclosureCode16: {
        disclosureIsTrue: 1,
        disclosureType: 2,
        disclosureCode: 16,
        disclosure1: '',
        disclosure2: '',
        disclosure3: '',
        disclosure5: '',
        disclosure4: '',
        disclosure7: '',
        phone: '',
        areaCode: '+86'
      },
      disclosureCode1: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 1,
        disclosure1: '',
        disclosure2: ''
      },
      // disclosureCode2: {
      //   disclosureIsTrue: 1,
      //   disclosureType: 3,
      //   disclosureCode: 2,
      //   disclosure1: ''
      // },
      disclosureCode3: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 3,
        disclosure1: ''
      },
      disclosureCode4: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 4,
        disclosure1: '',
        disclosure2: ''
      },
      disclosureCode5: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 5,
        disclosure1: '',
        disclosure2: '',
        disclosure3: ''
      },
      disclosureCode6: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 6,
        disclosure1: ''
      },
      disclosureCode7: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 7,
        disclosure1: ''
      },
      disclosureCode8: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 8,
        disclosure1: ''
      },
      // disclosureCode24: {
      //   disclosureIsTrue: 1,
      //   disclosureType: 3,
      //   disclosureCode: 24,
      //   disclosure1: ''
      // },
      disclosureCode28: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 28
      },
      disclosureCode27: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 27,
        disclosure1: ''
      },
      disclosureCode29: {
        disclosureIsTrue: 1,
        disclosureType: 3,
        disclosureCode: 29,
        disclosure1: '',
        disclosure2: ''
      }
    })
    // const change24 = () => {
    //   if (checked.value.includes(24)) {
    //     saveOpenInfoOfVuex({
    //       info: {
    //         disclosureCode24: {
    //           disclosureIsTrue: 1,
    //           disclosureType: 3,
    //           disclosureCode: 24,
    //           disclosure1: ''
    //         }
    //       }
    //     })
    //   }
    // }
    const changeChecked = (val: number[]) => {
      if (val.includes(14)) {
        form.disclosureCode14.disclosureIsTrue = 1
      }
      if (val.includes(15)) {
        form.disclosureCode15.disclosureIsTrue = 1
      }
      if (val.includes(16)) {
        form.disclosureCode16.disclosureIsTrue = 1
      }
    }
    // 保存步骤信息回调
    // saveOpenInfoDoneFunc(() => {
    //   saveOpenInfoOfVuex({ info: { checked, form } });
    //   router.push('/pi');
    // });
    // 按钮禁用
    const isDisabled = computed(() => {
      let result = checked.value.includes(28)
        ? checked.value?.length === 12
        : checked.value?.length === 11
      if (!checked.value.includes(14)) {
        const res = openInfo.value?.disclosureCode14
          ? openInfo.value?.disclosureCode14
          : form.disclosureCode14
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(15)) {
        const res = openInfo.value?.disclosureCode15
          ? openInfo.value?.disclosureCode15
          : form.disclosureCode15
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(16)) {
        const res = openInfo.value?.disclosureCode16
          ? openInfo.value?.disclosureCode16
          : form.disclosureCode16
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(1)) {
        const res = openInfo.value?.disclosureCode1
          ? openInfo.value?.disclosureCode1
          : form.disclosureCode1
        result = res.disclosure1 !== ''
      }
      // if (!checked.value.includes(2)) {
      //   const res = openInfo.value?.disclosureCode2
      //     ? openInfo.value?.disclosureCode2
      //     : form.disclosureCode2
      //   result = Object.values(res).every(item => item !== '')
      // }
      if (!checked.value.includes(3)) {
        const res = openInfo.value?.disclosureCode3
          ? openInfo.value?.disclosureCode3
          : form.disclosureCode3
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(4)) {
        const res = openInfo.value?.disclosureCode4
          ? openInfo.value?.disclosureCode4
          : form.disclosureCode4
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(5)) {
        const res = openInfo.value?.disclosureCode5
          ? openInfo.value?.disclosureCode5
          : form.disclosureCode5
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(6)) {
        const res = openInfo.value?.disclosureCode6
          ? openInfo.value?.disclosureCode6
          : form.disclosureCode6
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(7)) {
        const res = openInfo.value?.disclosureCode7
          ? openInfo.value?.disclosureCode7
          : form.disclosureCode7
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(8)) {
        const res = openInfo.value?.disclosureCode8
          ? openInfo.value?.disclosureCode8
          : form.disclosureCode8
        result = Object.values(res).every(item => item !== '')
      }
      if (!checked.value.includes(27)) {
        const res = openInfo.value?.disclosureCode27
          ? openInfo.value?.disclosureCode27
          : form.disclosureCode27
        result = Object.values(res).every(item => item !== '')
      }
      // if (!checked.value.includes(24) && idKind !== 2 && idKind !== 3) {
      //   const res = openInfo.value?.disclosureCode24
      //     ? openInfo.value?.disclosureCode24
      //     : form.disclosureCode24
      //   result = Object.values(res).every(item => item !== '')
      // }
      if (!checked.value.includes(29)) {
        result = true
      } else {
        result = true
      }
      return !result
    })

    // 保存下一步调用接口
    const saveNext = () => {
      const dataList = {
        disclosureCode14: openInfo.value.disclosureCode14,
        disclosureCode15: openInfo.value.disclosureCode15,
        disclosureCode16: openInfo.value.disclosureCode16,
        disclosureCode1: openInfo.value.disclosureCode1,
        // disclosureCode2: openInfo.value.disclosureCode2,
        disclosureCode3: openInfo.value.disclosureCode3,
        disclosureCode4: openInfo.value.disclosureCode4,
        disclosureCode5: openInfo.value.disclosureCode5,
        disclosureCode6: openInfo.value.disclosureCode6,
        disclosureCode7: openInfo.value.disclosureCode7,
        disclosureCode8: openInfo.value.disclosureCode8,
        // disclosureCode24: openInfo.value.disclosureCode24,
        disclosureCode28: openInfo.value.disclosureCode28,
        disclosureCode27: openInfo.value.disclosureCode27,
        disclosureCode29: openInfo.value.disclosureCode29
      }
      const percentage = stepPercentage(step)
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify({
          checked: checked.value,
          ...dataList
        })
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({ info: { checked: checked.value, ...dataList } })
        console.log(idKind, 'idkind', openInfo.value.disclosureCode24, openInfo.value.checked)
        router.push({ name: 'pi' })
      })
    }

    const handleNext = () => {
      saveNext()
    }

    onMounted(() => {
      setApptitle(headTitle)
      initData()
    })
    return {
      show,
      popContent,
      checked,
      checkedContent,
      changeChecked,
      closePopup,
      openPop,
      title,
      content,
      handleNext,
      isDisabled,
      imagesList,
      idKind
    }
  }
})
